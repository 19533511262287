//vendors
import React, {useCallback, useEffect, useMemo, useState} from 'react';

//layouts
import {VBox, HBox} from '@acc/layout';
import {useInput} from "hooks/input.hook";
import {SimpleTextField} from "@acc/form";
import {Loader} from "modules/common/loader";
import {Header} from "./header/header";
//styles
import "../app.scss";
import {ImpactDetailsContainer} from "../containers/impact_details.container";
import {AnnualSavingsContainer} from "../containers/annualSavings.container";

type Props = {
	data: Obj
	hasError: boolean,
	calculationInProgress: boolean,
	requestCalcEfficiency: (data: Obj) => void,
	requestCalcEfficiencyPDF: (data: Obj) => void,
};


export const App = (props: Props) => {
	const firstNameField = useInput("")
	const lastNameField = useInput("")
	const companyNameField = useInput("")
	const companyEmailField = useInput("")
	const numberOfProductionDatabasesField = useInput(1)
	const totalSizeOfProductionDatabasesField = useInput(2000)
	const copiesPerProdDatabaseField = useInput(5)

	const timeToProvisionField = useInput(8)
	const timeToRefreshField = useInput(5)
	const timeToRecoverField = useInput(16)
	const timeToAnonymizeField = useInput(5)
	const costPerHourField = useInput(75)

	const [advParams, toggleAdvParams] = useState<boolean>(false);

	function isValidEmail(email) {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	function isCompanyEmail(email) {
		const personalEmailDomains = [
			'gmail.com', 'google.com',
			'yahoo.com',
			'outlook.com', 'hotmail.com',
			'aol.com',
			'zoho.com',
			'mail.com',
			'gmx.com',
			'protonmail.com',
			'yandex.com',
			'icloud.com',
			'fastmail.com'
		];

		const domain = email.split('@')[1];

		return !personalEmailDomains.includes(domain);
	}

	const validation = useCallback(() => {
		!firstNameField.value.length ? firstNameField.setError('Field "First name" is required') : firstNameField.setError(undefined);
		!lastNameField.value.length ? lastNameField.setError('Field "Last name" is required') : lastNameField.setError(undefined);
		!companyNameField.value.length ? companyNameField.setError('Field "Company name" is required') : companyNameField.setError(undefined);
		if (!companyEmailField.value.length) {
			companyEmailField.setError('Field "Company email" is required')
		} else if (!isValidEmail(companyEmailField.value)) {
			companyEmailField.setError('Invalid email format')
		} else if (!isCompanyEmail(companyEmailField.value)) {
			companyEmailField.setError('Email belongs to a personal email service')
		} else {
			companyEmailField.setError(undefined)
		}
		!numberOfProductionDatabasesField.value.toString().length ? numberOfProductionDatabasesField.setError('Field "Number of production databases" is required') : numberOfProductionDatabasesField.setError(undefined);
		!copiesPerProdDatabaseField.value.toString().length ? copiesPerProdDatabaseField.setError('Field "Number of non-prod database copies per prod database" is required') : copiesPerProdDatabaseField.setError(undefined);
		!totalSizeOfProductionDatabasesField.value.toString().length ? totalSizeOfProductionDatabasesField.setError('Field "Total size of all production databases" is required') : totalSizeOfProductionDatabasesField.setError(undefined);

	}, [firstNameField.value, lastNameField.value, companyNameField.value,
					 companyEmailField.value, numberOfProductionDatabasesField.value,
					 copiesPerProdDatabaseField.value, totalSizeOfProductionDatabasesField.value]);

	useEffect(() => validation(), [validation]);

	const touchAll = () => {
		firstNameField.setTouched(true);
		lastNameField.setTouched(true);
		companyNameField.setTouched(true);
		companyEmailField.setTouched(true);
		numberOfProductionDatabasesField.setTouched(true);
		copiesPerProdDatabaseField.setTouched(true);
		totalSizeOfProductionDatabasesField.setTouched(true);
	};

	const isValid = useMemo(() => {
		return !firstNameField.error && !lastNameField.error && !companyNameField.error
			&& !companyEmailField.error && !numberOfProductionDatabasesField.error
			&& !copiesPerProdDatabaseField.error && !totalSizeOfProductionDatabasesField.error;
	}, [firstNameField.error, lastNameField.error, companyNameField.error, companyEmailField.error,
				   numberOfProductionDatabasesField.error, copiesPerProdDatabaseField.error,
					 totalSizeOfProductionDatabasesField.error]);


	const onCalculate = useCallback(() => {
		const data = {
			sendEmailToCustomer: true,
			firstName: firstNameField.value,
			lastName: lastNameField.value,
			companyName: companyNameField.value,
			companyEmail: companyEmailField.value,
			numberProdDBs: numberOfProductionDatabasesField.value,
			totalGbSizeProdDB: totalSizeOfProductionDatabasesField.value,
			numberNonProdDatabaseCopiesPerProdDBs: copiesPerProdDatabaseField.value,
			timeProvisionNonProdDB: timeToProvisionField.value,
			timeRefreshNonProdDB: timeToRefreshField.value,
			timeRecoverNonProdDB: timeToRecoverField.value,
			timeAnonymizeDataNonProdDB: timeToAnonymizeField.value,
			costPerHour: costPerHourField.value
		}

		touchAll()
		if (isValid) {
			props.requestCalcEfficiency(data)
		}
	}, [firstNameField,
		        lastNameField,
						companyNameField,
						companyEmailField,
						numberOfProductionDatabasesField,
						totalSizeOfProductionDatabasesField,
						copiesPerProdDatabaseField,
						timeToProvisionField,
						timeToRefreshField,
						timeToRecoverField,
						timeToAnonymizeField,
						costPerHourField,
					  isValid
	])

		return (
		<VBox size={"auto"} className="body">
			<div className="header">
				<Header />
			</div>
			<div className="container">
				<div className="container-head">
					<div className="container-head__content">
						<div className="container-head__content-left">
							ROI Calculator for IT Teams
						</div>
						<div className="container-head__content-right">
							How much can you save by implementing the Accelario Platform?
							Measure your financial and efficiency gain possibilities today.
						</div>
					</div>
				</div>
				<div className="container-body">
					<div className="container-body__left">
						<p className="container-body-title">Get Started</p>
						<SimpleTextField
							label="First Name"
							value={firstNameField.value}
							onChange={firstNameField.setValue}
							onBlur={() => firstNameField.setTouched(true)}
							touched={firstNameField.touched}
							className={"container-body__left-field"}
							horizontal={false}
							required={false}
							error={firstNameField.error}
						/>

						<SimpleTextField
							label="Last Name"
							value={lastNameField.value}
							onChange={lastNameField.setValue}
							onBlur={() => lastNameField.setTouched(true)}
							touched={lastNameField.touched}
							className={"container-body__left-field"}
							horizontal={false}
							required={false}
							error={lastNameField.error}
						/>

						<SimpleTextField
							label="Company Name"
							value={companyNameField.value}
							onChange={companyNameField.setValue}
							onBlur={() => companyNameField.setTouched(true)}
							touched={companyNameField.touched}
							className={"container-body__left-field"}
							horizontal={false}
							required={false}
							error={companyNameField.error}
						/>

						<SimpleTextField
							label="Company Email"
							value={companyEmailField.value}
							onChange={companyEmailField.setValue}
							onBlur={() => companyEmailField.setTouched(true)}
							touched={companyEmailField.touched}
							className={"container-body__left-field"}
							horizontal={false}
							required={false}
							error={companyEmailField.error}
						/>

						<SimpleTextField
							label="Number of production databases"
							type={"number"}
							value={numberOfProductionDatabasesField.value}
							onChange={numberOfProductionDatabasesField.setValue}
							onBlur={() => numberOfProductionDatabasesField.setTouched(true)}
							touched={numberOfProductionDatabasesField.touched}
							className={"container-body__left-field"}
							horizontal={false}
							required={false}
							error={numberOfProductionDatabasesField.error}
						/>

						<div className={"subLabel-field"}>
							<SimpleTextField
								label="Total size of all production databases"
								value={totalSizeOfProductionDatabasesField.value}
								onChange={totalSizeOfProductionDatabasesField.setValue}
								onBlur={() => totalSizeOfProductionDatabasesField.setTouched(true)}
								touched={totalSizeOfProductionDatabasesField.touched}
								className={"container-body__left-field"}
								horizontal={false}
								required={false}
								error={totalSizeOfProductionDatabasesField.error}
							/>
							<div className={"subLabel"}>GB</div>
						</div>
						<SimpleTextField
							label="Number of non-prod database copies per prod database"
							value={copiesPerProdDatabaseField.value}
							onChange={copiesPerProdDatabaseField.setValue}
							onBlur={() => copiesPerProdDatabaseField.setTouched(true)}
							touched={copiesPerProdDatabaseField.touched}
							className={"container-body__left-field"}
							horizontal={false}
							required={false}
							error={copiesPerProdDatabaseField.error}
						/>

						<button type="button" onClick={() => toggleAdvParams(value => !value)} className={"advParameters"}>
							Advanced Parameters {advParams ? " - " : " + "}
						</button>

						{advParams && <>
							<div className={"subLabel-field"}>
								<SimpleTextField
									label="Time to provision a non-production database"
									value={timeToProvisionField.value}
									onChange={timeToProvisionField.setValue}
									onBlur={() => timeToProvisionField.setTouched(true)}
									touched={timeToProvisionField.touched}
									className={"container-body__left-field"}
									horizontal={false}
									required={false}
									error={timeToProvisionField.error}
								/>
								<div className={"subLabel"}>hours</div>
							</div>

							<div className={"subLabel-field"}>
								<SimpleTextField
									label="Time to refresh a non-production database"
									value={timeToRefreshField.value}
									onChange={timeToRefreshField.setValue}
									onBlur={() => timeToRefreshField.setTouched(true)}
									touched={timeToRefreshField.touched}
									className={"container-body__left-field"}
									horizontal={false}
									required={false}
									error={timeToRefreshField.error}
								/>
								<div className={"subLabel"}>hours</div>
							</div>

							<div className={"subLabel-field"}>
								<SimpleTextField
									label="Time to recover a non-prod database"
									value={timeToRecoverField.value}
									onChange={timeToRecoverField.setValue}
									onBlur={() => timeToRecoverField.setTouched(true)}
									touched={timeToRecoverField.touched}
									className={"container-body__left-field"}
									horizontal={false}
									required={false}
									error={timeToRecoverField.error}
								/>
								<div className={"subLabel"}>hours</div>
							</div>

							<div className={"subLabel-field"}>
								<SimpleTextField
									label="Time to anonymize your data in a non-production database"
									value={timeToAnonymizeField.value}
									onChange={timeToAnonymizeField.setValue}
									onBlur={() => timeToAnonymizeField.setTouched(true)}
									touched={timeToAnonymizeField.touched}
									className={"container-body__left-field"}
									horizontal={false}
									required={false}
									error={timeToAnonymizeField.error}
								/>
								<div className={"subLabel"}>hours</div>
							</div>

							<div className={"subLabel-field"}>
								<SimpleTextField
									label="Cost per hour"
									value={costPerHourField.value}
									onChange={costPerHourField.setValue}
									onBlur={() => costPerHourField.setTouched(true)}
									touched={costPerHourField.touched}
									className={"container-body__left-field"}
									horizontal={false}
									required={false}
									error={costPerHourField.error}
								/>
								<div className={"subLabel"}>$</div>
							</div>
						</>
						}

						<p className="title smaller">
							Costs associated with the virtualization service are not included*
						</p>

						<button
							disabled={props.calculationInProgress}
							className="header-content__button"
							onClick={onCalculate}
						>
							Calculate
						</button>

					</div>

					{props.hasError && <div className="container-body__right  error">
						<p className="container-body-title">Error</p>
						<div className="container-body__right-top">
							<p className="config-info-row__title">For immediate assistance, please contact <a
								className="email"
								href="mailto:support@accelario.com?subject=Support%20request%20-%20Data%20Masking"
								target="_blank"
								rel="noopener noreferrer"
							>
								support@accelario.com.
							</a><br/>
								Apologies for the inconvenience. We're working to resolve this quickly.<br/>
								Thank you for yourpatience.
							</p>
						</div>
					</div>
					}

					{(props.calculationInProgress && !props.hasError) &&
						<HBox size="auto" height="337px" justify="center" align="end" className="container-body__right">
							<Loader size="large"/>
						</HBox>
					}

					{!!props.data && <div className="container-body__right">
						<div className="container-body__divider"></div>
						<p className="container-body-title">Analysis</p>
						<div className="container-body__right-top">
							<div className="config">
								<div className="config-info">
									<AnnualSavingsContainer/>

									<div className="divider"/>
									<div className="config-percent">
										<div className="config-percent__header">
											Total Annual Impact
										</div>
										<div className="config-percent__items">
											<div className="config-percent__items__item">
												<p className="config-percent__items__item-value">
													{props.data.totalAnnualImpactHoursPerTb}H
												</p>
											</div>
											<div className="config-percent__items__item">
												<p className="config-percent__items__item-value">
													{props.data.totalAnnualImpactGb}GB
												</p>
											</div>
											<div className="config-percent__items__item">
												<p className="config-percent__items__item-value">
													${props.data.totalAnnualImpactCost}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="container-body__right-bottom">
							<div className="container-body__right-bottom__title">
								<p className="title">Impact Details</p>
							</div>
							<ImpactDetailsContainer/>
						</div>
					</div>
					}
				</div>
			</div>
		</VBox>
		)
}